import React from 'react';

import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';

import Dashboard from '../pages/Dashboard';

import History from '../pages/History';

import Settings from '../pages/Settings';

import Order from '../pages/Order';

import ForgotPassword from '../pages/ForgotPassword';

import ResetPassword from '../pages/ResetPassword';

import AddBalance from '../pages/AddBalance';

const Routes: React.FC = () => {

	return (
		<Switch>
			<Route path="/" component={SignIn} exact />
			<Route path="/dashboard" component={Dashboard} isPrivate />
			<Route path="/history" component={History} isPrivate />
			<Route path="/settings" component={Settings} isPrivate />
			<Route path="/order" component={Order} isPrivate />
			<Route path="/forgot-password" component={ForgotPassword} />
			<Route path="/reset-password/token/:token/email/:email" component={ResetPassword} />
			<Route path="/add-balance" component={AddBalance} isPrivate />
		</Switch>
	);
}

export default Routes;