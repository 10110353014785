import { useState } from 'react';

import * as S from './styles';

import { useAuth } from '../../hooks/auth';

import Header from '../../components/Header';

import Menu from '../../components/Menu';

import Modal from '../../components/Modal';

import { useTranslation } from 'react-i18next';

export default function Dashboard() {

	const [modalShow, setModalShow] = useState<boolean>(false);
	const [shortcode, setShortcode] = useState<string>('');
	const [uri, setUri] = useState<string>('');

	const { profile } = useAuth();

	const { t } = useTranslation();

	function handleModalLikes(shortcode: string, uri: string) {

		setShortcode(shortcode);
		setUri(uri);
		setModalShow(true);
	}

	return (
		<S.Container>
			<Header model='full' />

			<S.Content>

				{!profile.profile && (
					<S.NoProfile>
						<h2>{t('selectAProfile')}</h2>
					</S.NoProfile>
				)}

				<S.ContentPosts>
					{profile.posts && profile.posts.map(post => (
						<button key={post.shortcode} onClick={() => handleModalLikes(post.shortcode, post.uri)}>
							<S.Post key={post.shortcode} src={post.uri} />
						</button>
					))}
				</S.ContentPosts>
			</S.Content>

			<Modal
				modalShow={modalShow}
				modalHide={() => {
					setModalShow(false);
					setShortcode('');
					setUri('');
				}}
				shortcode={shortcode}
				uri={uri}
			>
			</Modal>

			<Menu />
		</S.Container >
	);
}