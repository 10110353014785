import { useState } from 'react';

import Header from '../../components/Header';

import Menu from '../../components/Menu';

import ModalUpdateProfile from '../../components/ModalUpdateProfile';

import * as S from './styles';

import { useAuth } from '../../hooks/auth';

import { useTranslation } from 'react-i18next';

export default function Settings() {

	const [modalShow, setModalShow] = useState<boolean>(false);
	const [profile, setProfile] = useState<string>('');
	const [subscriptionId, setSubscriptionId] = useState<number>(0);

	const { user, subscriptions } = useAuth();

	const { t } = useTranslation();

	return (
		<S.Container>
			<Header model='simple' />
			<S.Content>
				<S.Hr />

				<S.Title2>{t('accountData')}</S.Title2>
				<S.Card>
					<S.TextCard>{t('userCode')}: {user.id}</S.TextCard>
					<S.TextCard>{t('name')}: {user.name}</S.TextCard>
					<S.TextCard>{t('email')}: {user.email}</S.TextCard>
				</S.Card>

				<S.Title2>{t('myActivePlans')}</S.Title2>

				{subscriptions.map(item => (
					<S.Card key={item.id}>
						<S.Status status={item.status} />
						<S.TextCard>{item.name} - {t(item.type_service)}</S.TextCard>
						<S.TextCard>{t('description')}: {item.description}</S.TextCard>
						<S.TextCard>{t('profile')}: {item.profile}</S.TextCard>
						<S.TextCard>{t('typePayment')}: {item.payment}</S.TextCard>
						<S.TextCard>
							{t('dueDate')}: {new Intl.DateTimeFormat('pt-BR').format(
								new Date(item.expiration_date)
							)}
						</S.TextCard>

						{item.status === 'ACTIVE_CANCELED' && (
							<S.TextCardWarning
								status={item.status}
							>
								{t('warningSubscriptionActiveCanceled')} {new Intl.DateTimeFormat('pt-BR').format(
									new Date(item.expiration_date)
								)}
							</S.TextCardWarning>
						)}

						{item.status === 'ACTIVE_LIKES' && (
							<S.TextCardWarning
								status={item.status}
							>
								{t('warningSubscriptionActiveLikes')}
							</S.TextCardWarning>
						)}
						<S.Hr />
						<S.ContentButtonsCard>
							<S.ButtonCard
								bg="#833AB4"
								onClick={() => {
									setModalShow(true);
									setSubscriptionId(item.id);
									setProfile(item.profile);
								}}
							>
								<S.TextButtonCard>
									{/* <S.IconButtonChange /> */}
									{t('titleUpdateProfiledModal')}
								</S.TextButtonCard>
							</S.ButtonCard>
							<S.ButtonCard
								bg="#FB2424"
							// onClick={() => handleCancelPlan(plan.id, plan.name, plan.profile, user.email)}
							>
								<S.TextButtonCard>
									{/* <S.IconButtonCancel /> */}
									{t('cancelSubscription')}
								</S.TextButtonCard>
							</S.ButtonCard>
						</S.ContentButtonsCard>
					</S.Card>
				))}


















				{/* 
				<S.Box>
					<S.Title>Planes</S.Title>


					<S.Subtitle>Seguidores Instagram:</S.Subtitle>
					{followers.map(plan => (

						<S.Plan key={plan.id}>
							<S.ColumnInfo>
								<S.Info>{`${plan.name}`}</S.Info>
								<S.Info>Perfil: {plan.profile}</S.Info>
								<S.Info>Tipo de pago: {plan.payment}</S.Info>
							</S.ColumnInfo>

							<S.ColumnButtons>
								<S.ButtonSettings
									type='button'
									model="update"
									onClick={() => handleChangeProfile(plan.id, plan.profile)}
								>Cambiar el perfil</S.ButtonSettings>

								<Link to={{ pathname: `https://api.whatsapp.com/send?phone=55&text=Ol%C3%A1,%20desejo%20cancelar%20meu%20plano!%20Meu%20e-mail:%20*${user.email}* - *${plan.name} de Seguidores Instagram* - *Perfil: ${plan.profile}*` }} target="_blank" rel="noopener noreferrer">
									<S.ButtonSettings
										type='button'
										model="cancel"
									>Cancelar plan</S.ButtonSettings>
								</Link>
							</S.ColumnButtons>
						</S.Plan>

					))}
					
					{likes.map(plan => (

						<S.Plan key={plan.id}>
							<S.ColumnInfo>
								<S.Info>{`${plan.name} de Curtidas Instagram`}</S.Info>
								<S.Info>Perfil: {plan.profile}</S.Info>
								<S.Info>Tipo de pago: {plan.payment}</S.Info>
							</S.ColumnInfo>

							<S.ColumnButtons>
								<S.ButtonSettings
									type='button'
									model="update"
									onClick={() => handleChangeProfile(plan.id, plan.profile)}
								>Cambiar el perfil</S.ButtonSettings>

								<Link to={{ pathname: `https://api.whatsapp.com/send?phone=55&text=Ol%C3%A1,%20desejo%20cancelar%20meu%20plano!%20Meu%20e-mail:%20*${user.email}* - *${plan.name} de Seguidores Instagram* - *Perfil: ${plan.profile}*` }} target="_blank" rel="noopener noreferrer">
									<S.ButtonSettings
										type='button'
										model="cancel"
									>Cancelar plan</S.ButtonSettings>
								</Link>
							</S.ColumnButtons>
						</S.Plan>
					))}
				</S.Box> */}
			</S.Content>

			<Menu />

			<ModalUpdateProfile
				modalShow={modalShow}
				modalHide={() => {
					setModalShow(false);
				}}
				subscriptionId={subscriptionId}
				profileOld={profile}
			></ModalUpdateProfile>
		</S.Container>
	);
}