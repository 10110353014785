import { useState } from 'react';

import * as S from './styles';

import { useAuth } from '../../hooks/auth';

import logo from '../../assets/images/logo-turbinei.png';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

export default function SignIn() {

	const { t } = useTranslation();

	const [loading, setLoading] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [error, setError] = useState<string>('');

	const { signIn } = useAuth();

	async function handleSignIn() {
		try {

			if (!email || !password) {

				setError(t('allRequiredFields'));

				return;
			}

			setLoading(true);

			await signIn({ email, password });
		} catch (error: any) {

			setLoading(false);
			setError(t('InvalidEmailOrPassword'));
		}
	}

	return (
		<S.Background>
			<S.Container>

				<S.Content>

					<S.ContentInput>
						<img src={logo} alt="" />

						<p>{t('login')}</p>

						<S.InputText
							label={t('email')}
							type="text"
							placeholder={t('emailPlaceholder')}
							onChange={(event: any) => {
								setEmail(event.target.value);
								setError('');
							}}
						/>

						<S.InputText
							label={t('password')}
							type="password"
							placeholder={t('passwordPlaceholder')}
							onChange={(event: any) => {
								setPassword(event.target.value);
								setError('');
							}}
						/>

						<S.Error>{error}</S.Error>

						<S.ContentButtons>
							<S.ButtonAction
								type="button"
								model='fill'
								loading={loading}
								onClick={() => handleSignIn()}
							>
								{t('buttonLogin')}
							</S.ButtonAction>
						</S.ContentButtons>


						<S.ForgotPassword>
							<Link to="/forgot-password">
								<span>{t('buttonForgotPassword')}</span>
							</Link>
						</S.ForgotPassword>
					</S.ContentInput>
				</S.Content>
			</S.Container>
		</S.Background>
	);
}