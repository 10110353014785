import styled from 'styled-components';

import { BsCoin } from 'react-icons/bs';

export const Container = styled.div`
	max-width: 550px;
	margin: 0 auto;
`;

export const Content = styled.div`
	min-height: 100vh;
	padding: 0 10px 10px 10px;
	margin-bottom: 40px;

	p {
		text-align: center;
	}
`;

export const CardCoin = styled.div`
	/* background: #F3F3F8;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;*/
	padding: 10px 5px; 
	margin: 10px 0;

		background: none;
		border: 2px solid #ebeaec !important;
		color: #3d3745;
		/* width: 150px; */
		border-radius: 10px;
		display: flex;
		/* align-items: center; */
		/* justify-content: center; */
		/* padding: 12px 20px; */

		justify-content: space-between;
	align-items: center;


	div {
		display: flex;
		/* background: red; */
		align-items: center;

		h2 {
			color: #3d3745;
			font-size: 14px;
			/* margin: 10px 0 8px 15px; */
			margin-left: 20px;
		}
	}

	button {
		border: none;
		background: green;
		color: #FFF;
		height: 25px;
		padding: 3px 10px;
		border-radius: 5px;
	}
`;

export const IconCoin = styled(BsCoin)`
	font-size: 35px;
	background: #ffe37c;
	padding: 5px;
	border-radius: 50px;
	color: #faa54f;
`;

export const Hr = styled.div`
	height: 1px;
	background: #E5E5E5;
	width: 80%;
	margin: 15px auto;
`;