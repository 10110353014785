import { ReactNode } from 'react';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Loader from "react-loader-spinner";

import * as S from './styles';

interface ButtonProps {
	children: ReactNode;
	model: 'fill' | 'noFilled';
	type: any;
	loading?: boolean;
	onClick?: () => any;
}

export default function Button({ loading, type, model, children, ...rest }: ButtonProps) {

	return (
		<S.Container model={model} {...rest}>
			{loading ? (
				<Loader
					type="TailSpin"
					color="#ffffff"
					height={15}
					width={15}
					timeout={0}
				/>
			) : children}
		</S.Container>
	);
}