import styled from 'styled-components';

import Button from '../../components/Button';

import backgroundImage from '../../assets/images/bg.png';

export const Background = styled.div`

	background-image: url(${backgroundImage});
	background-repeat: no-repeat;
	background-position: center;
`;

export const Container = styled.div`
	max-width: 550px;
	margin: 0 auto;
	height: 100%;
`;

export const Content = styled.div`

	padding: 10px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	h1 {
		font-size: 18px;
		font-weight: 600;
		text-align: center;
		margin-top: 20px;
	}
`;

export const ContentInput = styled.div`

	background: #FFF;
	border-radius: 5px;
	margin: auto 50px;
	padding: 35px 20px;

	p {

		font-size: 15px;
		text-align: center;
		color: #7e7e7e;
		font-weight: 500;
    	margin-top: 15px;
		margin-bottom: 15px
	}

	img {

		width: 170px;
		text-align: center !important;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		display: block;
	}
`;

export const ButtonAction = styled(Button)`

	background: #e80566;
	border-color: #e80566;
	border-radius: 5px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	display: block;
`;

export const Error = styled.span`
	color: #C10F41;
	text-align: center;
	font-size: 13px;
	margin-top: -5px;
`;

export const ForgotPassword = styled.div`
	
	margin-top: 12px;
	text-align: center;

	span {
		font-size: 13px;
		width: 130px;
	}
`;