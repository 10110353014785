import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Crisp } from 'crisp-sdk-web';

import * as S from './styles';

import { ReactComponent as IconHome } from '../../assets/images/icon-home.svg';

import { ReactComponent as IconChart } from '../../assets/images/icon-chart.svg';

import { ReactComponent as IconProfile } from '../../assets/images/icon-profile.svg';

import { ReactComponent as IconSupport } from '../../assets/images/icon-support.svg';

import { ReactComponent as IconCart } from '../../assets/images/icon-cart.svg';

import { useTranslation } from 'react-i18next';

import Loader from "react-loader-spinner";

import { useAuth } from '../../hooks/auth';

export default function Menu() {

	const [loadingChat, setLoadingChat] = useState<boolean>(false);

	const { t } = useTranslation();

	const { user, profileSelected, profile } = useAuth();

	const tokenId = String(user.id);

	useEffect(() => {

		Crisp.session.reset(true)
	}, []);

	Crisp.configure('a7e2a403-1f79-4f94-912a-9fac4789467b', {
		lockFullview: true,
		autoload: false,
		tokenId: tokenId,
	});

	Crisp.chat.onChatOpened(() => {

		Crisp.setTokenId(tokenId);
		Crisp.user.setEmail(user.email);
		Crisp.user.setNickname(`[Painel]-${user.name ? user.name : ''}`);
		Crisp.session.setSegments(["painel"], true);
		Crisp.message.send(
			"text",
			`
			**Informações:**\n
			**E-mail: ** ${user.email} 
			**Código da Assinatura: ** ${profileSelected.subscriptionId ? profileSelected.subscriptionId : '*(Não selecionado)*'}
			**Perfil: ** ${profileSelected.profile ? profileSelected.profile : '*(Não selecionado)*'}
			`
		);
		Crisp.user.setAvatar(`${profile.profile ? profile.profile : null}`);

		Crisp.chat.show()
	})

	Crisp.chat.onChatClosed(() => {

		setLoadingChat(false);

		Crisp.chat.hide();
	})

	function handleChat() {

		setLoadingChat(true);

		Crisp.chat.open();
	}

	return (
		<S.Container>
			{loadingChat && (
				<S.LoadingChat>
					<Loader
						type="TailSpin"
						color="#ffffff"
						height={35}
						width={35}
						timeout={0}
					/>

					<span>{t('waitingChat')}!</span>
				</S.LoadingChat>
			)}

			<S.ContentMenu>
				<Link to="/dashboard">
					<div>
						<IconHome />
						<p>{t('home')}</p>
					</div>
				</Link>

				<Link to="/history">
					<div>
						<IconChart />
						<p>{t('history')}</p>
					</div>
				</Link>

				<div>
					<button onClick={() => handleChat()}>
						<IconSupport />
						<p>{t('support')}</p>
					</button>
				</div>

				<Link to="/add-balance">
					<div>
						<IconCart />
						<p>{t('addFunds')}</p>
					</div>
				</Link>

				<Link to="/settings">
					<div>
						<IconProfile />
						<p>{t('account')}</p>
					</div>
				</Link>
			</S.ContentMenu>
		</S.Container>
	);
}