import { useState } from "react";

import * as S from "./styles";

import { useTranslation } from "react-i18next";

import { useAuth } from '../../hooks/auth';

interface ModalProps {
	modalShow: boolean;
	modalHide: () => void;
	subscriptionId: number;
	profileOld: string;
}

function ModalUpdateProfile({ modalShow, modalHide, subscriptionId, profileOld }: ModalProps) {

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [profile, setProfile] = useState<string>('');

	const { t } = useTranslation();

	const { handleUpdateProfileSubscription } = useAuth();

	async function handleUpdateProfile() {

		if (!profile) {

			setError(t('enterProfile'));

			return;
		}

		const response = await handleUpdateProfileSubscription(subscriptionId, profile)

		setLoading(true);

		if (response) {

			setLoading(false);

			modalHide();
		}
	}

	return (
		<S.Container>

			{modalShow && (
				<>
					<S.Backdrop />
					<S.Wrapper>

						<S.Header>
							<S.TitleHeader>{t('titleUpdateProfiledModal')}: {profileOld}</S.TitleHeader>
						</S.Header>

						<S.Content>

							<S.InputText
								label={t('newProfile')}
								type="text"
								value={profile}
								placeholder="Ex: turbinei.com"
								onChange={(event: any) => {
									setProfile(event.target.value);
									setError('');
								}}
							/>

							<S.Error>{error}</S.Error>

							<S.ContentButtons>
								<S.ButtonAction
									type="button"
									model='noFilled'
									onClick={() => {
										modalHide();
										setProfile('');
									}}
								>
									{t('buttonCancelModal')}
								</S.ButtonAction>

								<S.ButtonAction
									type="button"
									model='fill'
									onClick={() => handleUpdateProfile()}
									loading={loading}
								>
									{t('buttonUpdatePassword')}
								</S.ButtonAction>
							</S.ContentButtons>
						</S.Content>
					</S.Wrapper>
				</>
			)}
		</S.Container>
	);
};

export default ModalUpdateProfile;