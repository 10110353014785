import { useEffect, useState } from 'react';

import { format } from 'date-fns';

import * as S from './styles';

import { Line } from 'react-chartjs-2';

import Menu from '../../components/Menu';

import api from '../../services/api';

import { useAuth } from '../../hooks/auth';

import Header from '../../components/Header';

import Loader from "react-loader-spinner";

import { useTranslation } from 'react-i18next';

interface HistoryProps {
	id: number;
	link: string;
	quantity: number;
	send_date: Date;
	id_service: number;
	status: string;
	cost: number;
	type_service: string;
}

interface HistoryFundsProps {
	id: number;
	created_at: Date;
	authorized_date: Date;
	quantity: number;
	sale_code: number;
	status: string;
}

function History() {

	const [history, setHistory] = useState<HistoryProps[]>([]);
	const [historyLastDays, setHistoryLastDays] = useState<HistoryProps[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [selectedTab, setSelectedTab] = useState<string>('sent');
	const [funds, setFunds] = useState<HistoryFundsProps[]>([]);

	const { profileSelected, profile } = useAuth();

	const { t } = useTranslation();

	useEffect(() => {

		if (!profileSelected.subscriptionId) return;

		handleHistorySent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileSelected]);

	const data = {
		labels: historyLastDays.slice(0, 7).reverse().map(item => formatDate(item.send_date)),
		datasets: [
			{
				label: t('instagramFollowers'),
				data: historyLastDays.slice(0, 7).reverse().map(item => item.quantity),
				fill: true,
				backgroundColor: "#EAEAEA",
				pointBorderColor: "#DE496E",
				borderColor: '#DE496E',
				pointBorderWidth: 2,
				borderWidth: 2,
				pointRadius: 3,
				tension: 0.4
			},
		],
	};

	const options = {
		plugins: { legend: { display: false } },
		layout: { padding: { bottom: 100 } },
		scales: {
			y: {
				ticks: {
					color: "#959595",
					font: {
						size: 13
					}
				},
				grid: {
					color: "#F2F5FF"
				}
			},
			x: {
				ticks: {
					color: "#959595",
					font: {
						size: 13
					}
				}
			}
		},
	};

	const statusType: any = {
		'WAITING_PAYMENT': t('fundsWaiting'),
		'APPROVED': t('fundsApproved'),
		'CANCELLED': t('fundsCancelled'),
		'REFUNDED': t('fundsRefunded')
	}

	function formatDate(date: Date) {

		const dateFull = new Date(date);

		const dateFormated = format(dateFull, "dd/MM");

		return dateFormated;
	}

	function formatDateFull(date: Date) {

		const dateFull = new Date(date);

		const dateFormated = format(dateFull, "dd/MM/yyyy");

		return dateFormated;
	}

	function handleTabChange(tab: string) {

		setSelectedTab(tab);

		tab === 'sent' ? handleHistorySent() : handleHistoryFunds();
	}

	function handleHistorySent() {

		api.get(`/historic/subscription/${profileSelected.subscriptionId}`).then(response => {

			setHistory(response.data.historic);
			setHistoryLastDays(response.data.lastDays);
			setLoading(false);
		});
	}

	function handleHistoryFunds() {

		api.get('/historic/user-funds').then(response => {

			setFunds(response.data);
		})
	}

	return (

		<S.Container>
			<Header model='simple' />

			<S.Content>
				{!profile.profile && (
					<S.NoProfile>
						<h2>{t('selectAProfile')}</h2>
					</S.NoProfile>
				)}

				{profile.profile && (
					<>
						{loading && (
							<S.ContentLoading>
								<Loader
									type="TailSpin"
									color="#5e48c6"
									height={35}
									width={35}
									timeout={0}
								/>
								<S.Title>{t('searchingForInformation')}</S.Title>
							</S.ContentLoading>
						)}

						<S.Title>{t('graphLastDays')}</S.Title>
						<Line data={data} options={options} />

						<S.ContentTabButton>
							<S.TabButton
								isActive={selectedTab === 'sent'}
								onClick={() => handleTabChange('sent')}
							>Enviados</S.TabButton>

							<S.TabButton
								isActive={selectedTab === 'funds'}
								onClick={() => handleTabChange('funds')}
							>Saldos</S.TabButton>
						</S.ContentTabButton>

						{selectedTab === 'sent' && (
							<S.ContentHistory>
								<S.Title>{t('historyLastDays')}</S.Title>

								{history.map(item => (
									<S.History key={item.id}>
										<S.Descriptions>
											<S.ServiceAndDate>{item.link.substring(0, 38)} - {formatDateFull(item.send_date)}</S.ServiceAndDate>
											<S.Service>{t(item.type_service)}</S.Service>
											<S.Quantity>{t('quantity')}: {item.quantity}</S.Quantity>
											<S.Quantity>{item.cost ? `${t('cost')}: ${item.cost}` : ''}</S.Quantity>
										</S.Descriptions>
										<S.Status status={item.status}>{t(item.status)}</S.Status>
									</S.History>
								))}
							</S.ContentHistory>
						)}
					</>
				)}

				{selectedTab === 'funds' && (
					<S.ContentHistory>
						<S.Title>{t('historyFunds')}</S.Title>

						<S.StyledTable>
							<thead>
								<tr>
									<S.TableHeader>{t('code')}</S.TableHeader>
									<S.TableHeader>{t('date')}</S.TableHeader>
									<S.TableHeader>{t('quantity')}</S.TableHeader>
									<S.TableHeader>{t('status')}</S.TableHeader>
								</tr>
							</thead>

							<tbody>
								{funds.map(item => (
									<tr key={item.id}>
										<S.TableCell>{item.sale_code}</S.TableCell>
										<S.TableCell>{format(new Date(item.created_at), 'dd/MM/yyyy HH:mm:ss')}</S.TableCell>
										<S.TableCell>{item.quantity}</S.TableCell>
										<S.TableCellStatus status={item.status}>{statusType[item.status]}</S.TableCellStatus>
									</tr>
								))}
							</tbody>
						</S.StyledTable>

					</S.ContentHistory>
				)}
			</S.Content>

			<Menu />
		</S.Container>
	);
}

export default History;
