import styled, { css } from 'styled-components';

interface IStatus {
	status: string;
}

interface TabButtonProps {
	isActive: boolean;
}

export const Container = styled.div`
	max-width: 550px;
	margin: 0 auto;
`;

export const Content = styled.div`
	background: #FFF;
	min-height: 100vh;
	padding: 10px;
	position: relative;
`;

export const NoProfile = styled.h2`

	h2 {
		font-size: 15px;
		margin-top: 50%;
		color: #9797BD;
		text-align: center;
		font-weight: 500;
	}
`;

export const Title = styled.h2`
	color: #272727;
	font-size: 15px;
	font-weight: 600;
	margin-top: 5px;
	margin-bottom: 15px;
	text-align: center;
`;

export const ContentTabButton = styled.div`
	background: #eaeaea;
	width: 164px;
	padding: 2px;
	border-radius: 4px;
	margin: 0 auto;
	margin-top: -80px;
`;

export const TabButton = styled.button<TabButtonProps>`
	background-color: ${(props) => (props.isActive ? '#683984' : '#eaeaea')};
	color: ${(props) => (props.isActive ? '#FFF' : '#646464')};
	width: 80px;
	padding: 10px 0;
	padding: 6px 12px;
	border-radius: 4px;
	border: none;
	transition: background-color 0.8s, color 0.3s, border-radius 0.3s;
`;

export const ContentHistory = styled.div`
	margin-top: 17px;
	margin-bottom: 70px;
`;

export const History = styled.div`
	background: #FFF;
	box-shadow: 0px 0px 10px rgba(0, 0, 4, 0.12);
	padding: 12px 10px;
	border-radius: 10px;
	margin: 10px 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const Descriptions = styled.div`

`;

export const Status = styled.div<IStatus>`
	font-size: 14px;
	display: flex;
	align-items: center;

	${({ status }) => status === 'Canceled' && css`
		color: #C10F41;
	`}

	${({ status }) => status === 'Pending' && css`
		color: #FACC58;
	`}

	${({ status }) => status === 'Completed' && css`
		color: #098453;
	`}

	${({ status }) => status === 'In progress' && css`
		color: #FACC58;
	`}

	${({ status }) => status === 'Partial' && css`
		color: #FACC58;
	`}

	${({ status }) => status === 'Awaiting' && css`
		color: #FACC58;
	`}

	${({ status }) => status === 'Processing' && css`
		color: #FACC58;
	`}

	${({ status }) => !status && css`
		color: #FACC58;
	`}
`;

export const ServiceAndDate = styled.p`
	color: #646464;
	font-size: 15px;
`;

export const Service = styled.p`
	color: #9797BD;
	font-size: 14px;
	font-weight: 500;
	margin-top: 2px;
`;

export const Quantity = styled.p`
	color: #9797BD;
	font-size: 14px;
	font-weight: 500;
	margin-top: 2px;
`;

export const ContentLoading = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 25%;
`;

export const StyledTable = styled.table`
  	width: 100%;

	tbody {

		tr {
			&:nth-child(even) {
				background-color: #f2f2f2;
			}
			&:nth-child(odd) {
				background-color: #fff;
			}
		}
	}
`;

export const TableHeader = styled.th`
	padding: 8px;
	background-color: #c3c3c3;
	border-radius: 4px;
`;

export const TableCell = styled.td`
  	padding: 8px;
`;

export const TableCellStatus = styled.td<IStatus>`
  	padding: 8px;

  	${({ status }) => status === 'CANCELLED' && css`
		color: #C10F41;
	`}

	${({ status }) => status === 'WAITING_PAYMENT' && css`
		color: #0863d2;
	`}

	${({ status }) => status === 'APPROVED' && css`
		color: #098453;
	`}

	${({ status }) => status === 'REFUNDED' && css`
		color: #000;
	`}
`;