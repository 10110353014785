import { useState } from 'react';

import Header from '../../components/Header';

import Menu from '../../components/Menu';

import Modal from '../../components/Modal';

import Select from 'react-select';

import * as S from './styles';

import { useTranslation } from 'react-i18next';

interface IServices {
	value: string;
	label: string;
}

interface IDataService {
	service: string | undefined;
	labelService: string | undefined;
	network: string | undefined;
}

export default function Order() {

	const [networkSelected, setNetworkSelected] = useState<string>();
	const [servicesSelected, setServicesSelected] = useState<IServices[]>([]);
	const [modalShow, setModalShow] = useState<boolean>(false);
	const [dataService, setDataService] = useState<IDataService>({} as IDataService);

	const { t } = useTranslation();

	const network = [
		{ value: 'Instagram', label: 'Instagram' },
		{ value: 'TikTok', label: 'TikTok' }
	];

	const servicesInstagram = [
		{ value: 'ig101', label: 'Curtidas em Feed/Reels/Igtv' },
		{ value: 'ig102', label: 'Visualizações em Feed/Reels/Igtv' },
		{ value: 'ig103', label: 'Visualizações em Stories' }
	];

	const servicesTikTok = [
		{ value: 'tk201', label: 'Seguidores' },
		{ value: 'tk202', label: 'Curtidas' },
		{ value: 'tk203', label: 'Visualizações' }
	];

	function handleNetwork(network: string | undefined) {

		setNetworkSelected(network);

		if (network === 'Instagram') {
			setServicesSelected(servicesInstagram);
		}

		if (network === 'TikTok') {
			setServicesSelected(servicesTikTok);
		}
	}

	function handleModalService(data: IDataService) {

		setDataService(data);

		setModalShow(true);
	}

	return (
		<S.Container>
			<Header model='simple' />

			<S.Content>

				<S.Info>
					<S.Description>{t('useYourBalance')}</S.Description>

					<S.Note>{t('noticeSendService')}</S.Note>
				</S.Info>

				<S.Label>{t('chooseYourSocialNetwork')}</S.Label>
				<Select
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							borderColor: '#3D3744',
							borderWidth: 2
						}),
					}}
					theme={(theme) => ({
						...theme,
						borderRadius: 5,
						colors: {
							...theme.colors,
							primary: '#3D3744',
						},
					})}
					options={network}
					onChange={(event) => handleNetwork(event?.value)}
					placeholder={t('selectPlaceholder')}
				/>

				{networkSelected && (
					<>
						<S.Label>{t('ChooseTypeService')}</S.Label>
						<Select
							styles={{
								control: (baseStyles, state) => ({
									...baseStyles,
									borderColor: '#3D3744',
									borderWidth: 2
								}),
							}}
							theme={(theme) => ({
								...theme,
								borderRadius: 5,
								colors: {
									...theme.colors,
									primary: '#3D3744',
								},
							})}
							options={servicesSelected}
							placeholder={t('selectPlaceholder')}
							onChange={(event) => handleModalService({ service: event?.value, labelService: event?.label, network: networkSelected })}
						/>
					</>
				)}
			</S.Content>

			<Menu />

			<Modal
				modalShow={modalShow}
				modalHide={() => {
					setModalShow(false);
					setNetworkSelected('');
					setServicesSelected([]);
				}}
				dataService={dataService}
			>
			</Modal>
		</S.Container>
	);
}