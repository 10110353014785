import { useState } from 'react';

import * as S from './styles';

import Input from '../../components/Input';

import { Link } from 'react-router-dom';

import api from '../../services/api';

import logo from '../../assets/images/logo-turbinei.png';

import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';

export default function ForgotPassword() {

	const [loading, setLoading] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [messageSuccess, setMessageSuccess] = useState(false);

	const { t } = useTranslation();

	async function handleSendEmailForgotPassword() {

		if (!email) {

			setError(t('emailIsRequired'));

			return;
		}

		const lang = i18n.language === 'es' ? 'tbes' : 'tb';

		setLoading(true);

		await api.post('/users/forgot-password', { email }, {
			headers: {
				company: lang
			}
		});

		setLoading(false);
		setMessageSuccess(true);
		setEmail('');
	}

	return (
		<S.Background>
			<S.Container>

				<S.Content>
					{messageSuccess ? (
						<>
							<h1>{t('checkYourEmail')}</h1>
							<p>{t('weSendRegisteredEmail')}</p>
						</>
					) : (
						<>
							<S.ContentInput>
								<img src={logo} alt="" />

								<h1>{t('passwordRecovery')}</h1>

								<p>{t('linkEmailPasswordRecovery')}</p>

								<Input
									label={t('email')}
									type="text"
									value={email}
									placeholder={t('emailPlaceholder')}
									onChange={(event: any) => {
										setEmail(event.target.value);
										setError('');
									}}
								/>

								<S.Error>{error}</S.Error>

								<S.ButtonAction
									type="button"
									model='fill'
									loading={loading}
									onClick={() => handleSendEmailForgotPassword()}
								>
									{t('buttonSend')}
								</S.ButtonAction>

								<S.ForgotPassword>
									<Link to="/">
										<span>{t('backToLogin')}</span>
									</Link>
								</S.ForgotPassword>
							</S.ContentInput>
						</>
					)}
				</S.Content>
			</S.Container>
		</S.Background>
	);
}