import { useState } from 'react';

import * as S from './styles';

import { ReactComponent as IconSelect } from '../../assets/images/icon-select.svg';

import ModalChangePassword from '../ModalUpdatePassword';

import { useAuth } from '../../hooks/auth';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

interface HeaderProps {
	model: 'simple' | 'full'
}

export default function Header({ model }: HeaderProps) {

	const { user, profile, balance, subscriptions, signOut, handleSelectedProfile } = useAuth();

	const [showMenu, setShowMenu] = useState<boolean>(false);
	const [modalUpdateShow, setModalUpdateShow] = useState<boolean>(false);

	const { t } = useTranslation();

	return (
		<>
			<S.Container>

				<S.ContentTop>
					<button onClick={() => setShowMenu(!showMenu)}>
						<h1>{profile.username ? profile.username : t('selectAProfile')}</h1>
						<IconSelect />
					</button>

					<div>
						{balance.balance ? balance.balance : 0} <S.CoinIco />
					</div>
				</S.ContentTop>

				<S.ContentDataProfile>
					{profile.profile && (
						<>
							<img src={profile.profile} alt='' />

							<S.InfoProfile>
								<div>
									<h1>{profile.postsFeed ? profile.postsFeed : 0}</h1>
									<span>Posts</span>
								</div>
								<S.DotsIcon />
								<div>
									<h1>{profile.followers ? profile.followers : 0}</h1>
									<span>Followers</span>
								</div>
								<S.DotsIcon />
								<div>
									<h1>{profile.following ? profile.following : 0}</h1>
									<span>Following</span>
								</div>
							</S.InfoProfile>
						</>
					)}
				</S.ContentDataProfile>

				{model === 'full' && (
					<>
						{profile.profile && (
							<S.ContentBio>
								<div>
									<div>
										<h2>{profile.fullName}</h2>
										<Link to="/order">
											<button>{t('extraServices')}</button>
										</Link>
									</div>
									<p>{profile.biography}</p>
								</div>
							</S.ContentBio>
						)}
					</>
				)}

				{showMenu && (
					<S.MenuDropdown>
						{subscriptions.map(item => item.type_service === 'followers' && (
							<button
								key={item.id}
								onClick={() => {
									setShowMenu(!showMenu);
									handleSelectedProfile(Number(item.id), item.profile)
								}}
							>
								<S.IconUser />
								<h1>{item.profile}</h1>
							</button>
						))}

						<S.Hr />

						<button onClick={() => {
							setModalUpdateShow(true);
							setShowMenu(!showMenu);
						}}>
							<S.IconUpdatePassword />
							<h1>{t('titleUpdatePasswordModal')}</h1>
						</button>

						<button onClick={() => signOut()}>
							<S.IconGoOut />
							<h1>{t('logout')}</h1>
						</button>
					</S.MenuDropdown>
				)}
			</S.Container>

			<ModalChangePassword
				modalShow={modalUpdateShow}
				modalHide={() => {
					setModalUpdateShow(false);
				}}
				userId={user.id}
			></ModalChangePassword>
		</>
	);
}