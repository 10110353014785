import { useState } from "react";

import api from "../../services/api";

import * as S from "./styles";

import { useTranslation } from "react-i18next";

interface ModalProps {
	modalShow: boolean;
	modalHide: () => void;
	userId: number;
}

function ModalUpdatePassword({ modalShow, modalHide, userId }: ModalProps) {

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [password, setPassword] = useState<string>();
	const [confirmPassword, setConfirmPassword] = useState<string>();

	const { t } = useTranslation();

	async function handleUpdatePassword() {

		if (!password || !confirmPassword) {
			setError(t('allRequiredFields'));

			return;
		}

		if (password !== confirmPassword) {
			setError(t('differentPasswords'));

			return;
		}

		setLoading(true);

		const update = await api.put('/users/account/reset-password', { password });

		if (update.status === 201) {

			setLoading(false);
			setPassword('');
			setConfirmPassword('');
			modalHide();
		}
	}

	return (
		<S.Container>

			{modalShow && (
				<>
					<S.Backdrop />
					<S.Wrapper>

						<S.Header>
							<S.TitleHeader>{t('titleUpdatePasswordModal')}</S.TitleHeader>
						</S.Header>

						<S.Content>

							<S.InputText
								label={t('inputNewPasswordUpdatePasswordModal')}
								type="password"
								value={password}
								placeholder="* * * * * * *"
								onChange={(event: any) => {
									setPassword(event.target.value);
									setError('');
								}}
							/>

							<S.InputText
								label={t('inputConfirmPasswordUpdatePasswordModal')}
								type="password"
								value={confirmPassword}
								placeholder="* * * * * * *"
								onChange={(event: any) => {
									setConfirmPassword(event.target.value);
									setError('');
								}}
							/>

							<S.Error>{error}</S.Error>

							<S.ContentButtons>
								<S.ButtonAction
									type="button"
									model='noFilled'
									onClick={() => {
										modalHide();
										setPassword('');
										setConfirmPassword('');
									}}
								>
									{t('buttonCancelModal')}
								</S.ButtonAction>

								<S.ButtonAction
									type="button"
									model='fill'
									onClick={() => handleUpdatePassword()}
									loading={loading}
								>
									{t('buttonUpdatePassword')}
								</S.ButtonAction>
							</S.ContentButtons>
						</S.Content>
					</S.Wrapper>
				</>
			)}
		</S.Container>
	);
};

export default ModalUpdatePassword;