import * as S from './styles';

function Input({ ...rest }) {

	return (
		<S.Container>
			<label>{rest.label} *</label>
			<input {...rest} />
		</S.Container>
	);
}

export default Input;