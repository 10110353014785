import styled from 'styled-components';

import { ReactComponent as IconPlus } from '../../assets/images/icon-plus.svg';

export const Container = styled.div`
	width: 100% !important;
	position: fixed;
	bottom: 0;
`;

export const LoadingChat = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 500;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	span {
		color: #FFF;
		font-size: 16px;
		margin-top: 10px;
	}
`;

export const ContentMenu = styled.div`
	background: #FFF;
	box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.2);
	border-radius: 10px 10px 0 0;
	height: 60px;
	max-width: 550px;

	display: flex;
	justify-content: space-around;
	align-items: center;

	div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		p {
			color: #432C66;
			margin-top: 2px;
			font-size: 12px;
		}

		button {
			border: none;
			background: none;
		}
	}
`;

export const ContentFab = styled.div`
	background: #DE496E;
	width: 50px;
	height: 50px;
	border-radius: 10px;
	margin-top: -35px;
	border: 5px solid #FFF;

	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
`;

export const BoxIconPlus = styled(IconPlus)`
	background: #DE496E;
`;