import styled from 'styled-components';

import { MdOutlineLogout } from 'react-icons/md';

import { RiLockPasswordLine } from 'react-icons/ri';

import { BiUser } from 'react-icons/bi';

import { BsCoin } from 'react-icons/bs';

import { BiDotsVerticalRounded } from 'react-icons/bi';

export const Container = styled.div`
	padding: 0 10px;

	button {
		display: flex;
		align-items: center;
		background: none;
		border: none;

		h1 {
			font-size: 13px;
			color: #272730;
			margin-right: 8px;
		}
	}
`;

export const ContentTop = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 5px;

	h1 {
		font-size: 14px !important; 
	}

	div {
		display: flex;
		align-items: center;
		font-size: 13px;
	}
`;

export const CoinIco = styled(BsCoin)`
	margin-left: 5px;
`;

export const ContentDataProfile = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 15px 0;

	img {
		width: 70px;
		border-radius: 10px;
	}
`;

export const InfoProfile = styled.div`

	display: flex;
	align-items: center;

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 15px;
		
		h1 {
			color: #272730;
			font-size: 18px;
		}

		span {
			color: #645f6b;
		}
	}
`;

export const ContentBio = styled.div`

	display: flex;
	align-items: center;

	div {

		

		p {
			color: #645f6b;
			font-weight: 500;
			font-size: 15px;
		}

		button {
			background: none;
			border: 2px solid #ebeaec !important;
			color: #3d3745;
			width: 150px;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 7px 12px;
			margin-left: 20px;
			margin-bottom: 5px;
		}

		div {
			display: flex;
			justify-content: space-between;
			align-items: end;

			h2 {
			color: #272730;
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 5px;
		}
		}
	}
`;

export const DotsIcon = styled(BiDotsVerticalRounded)`
	font-size: 20px;
	color: #d0d0d0;
`;

export const ContentButtons = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;

	button {
		background: none;
		border: 2px solid #ebeaec !important;
		color: #3d3745;
		width: 150px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px 20px;
	}
`;

export const Info = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 55px;
    position: absolute;
    right: 18px;

	h2 {
		color: #FFF;
		font-weight: bold;
		font-size: 20px;
	}

	p {
		color: #FFF;
		margin-top: 5px;
		font-weight: 600;
	}
`;

export const MenuDropdown = styled.div`
    background: #f2f5ff;
    min-width: 170px;
    position: absolute;
    top: 80px;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px -4px 20px rgb(0 0 0 / 20%);
	z-index: 9;

	button {
		margin: 8px 0;

		h1 {
			font-size: 15px;
			font-weight: 600;
			color: #432C66;
			margin-left: 5px;
		}
	}
`;

export const Hr = styled.div`
	background: #e7e7e8;
	height: 1px;
	margin: 10px 0; 
`;

export const IconGoOut = styled(MdOutlineLogout)`
	font-size: 20px;
	color: #89898b;
`;

export const IconUpdatePassword = styled(RiLockPasswordLine)`
	font-size: 20px;
	color: #89898b;
`;

export const IconUser = styled(BiUser)`
	font-size: 20px;
	color: #89898b;
`;