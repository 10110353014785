import { useAuth } from '../../hooks/auth';

import Header from '../../components/Header';

import Menu from '../../components/Menu';

import * as S from './styles';

import data from './packages.json';

import { useTranslation } from 'react-i18next';

export default function AddBalance() {

	const { packages } = data;

	const { user } = useAuth();

	const { t } = useTranslation();

	function handlePayment(link: string) {

		const url = `${link}?nome=${user.name}&email=${user.email}`

		window.location.href = url;
	}

	return (
		<S.Container>
			<Header model='simple' />

			<S.Content>
				<S.Hr />

				<p>{t('titleAddBalance')}</p>

				{packages.map(item => (
					<S.CardCoin key={item.link}>
						<div>
							<S.IconCoin />
							<h2>{item.quantity} Coin - {t('coinPer')} {item.currencySymbol} {item.price}</h2>
						</div>
						<button onClick={() => handlePayment(item.link)}>
							{t('buttonPurchase')}
						</button>
					</S.CardCoin>
				))}
			</S.Content>

			<Menu />
		</S.Container>
	);
}