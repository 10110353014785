import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin: 12px auto;
	max-width: 370px;

	label {
		font-size: 13px;
		margin-bottom: 5px;
		color: #3D3744;
	}

	input {
		border: 2px solid #3D3744;
	}
`;