import styled from 'styled-components';

import Button from '../../components/Button';

interface PropsContentHeader {
	uriProfile: string;
}

export const Container = styled.div`
	max-width: 550px;
	margin: 0 auto;
	height: 100%;
`;

export const ContentHeader = styled.div<PropsContentHeader>`
	height: 130px;
	display: flex;
	align-items: center;
	padding: 20px;
	background-size: cover;
	background: linear-gradient(to left,#0000008a 0%,#000 80%), url(${(props) => props.uriProfile}) no-repeat center center ;

	button {
		display: flex;
		align-items: center;
		background: none;
		border: none;

		h1 {
			font-size: 13px;
			color: #FFF;
			margin-right: 8px;
		}
	}
`;

export const Content = styled.div`
	
	background: #FFF;
	border-radius: 15px 15px 0 0;
	padding: 10px;
	margin-top: -18px;
	min-height: 100vh;

	display: flex;
	flex-direction: column;

	h1 {
		font-size: 25px;
		font-weight: 800;
		text-align: center;
		margin-top: 40px;
	}

	p {
		font-size: 15px;
		text-align: center;
		color: #7e7e7e;
		font-weight: 500;
    	margin-top: 15px;
	}
`;

export const ContentInput = styled.div`
	margin-top: 20px;
`;

export const ButtonAction = styled(Button)`
	margin: 10px auto;
`;

export const Error = styled.span`
	color: #C10F41;
	text-align: center;
	font-size: 13px;
	margin-top: -5px;
`;

export const ForgotPassword = styled.div`
	
	margin-top: 12px;
	text-align: center;
	span {
		font-size: 13px;
		width: 130px;
	}
`;