import { useState } from "react";

import api from "../../services/api";

import { useAuth } from '../../hooks/auth';

import * as S from "./styles";

import { useTranslation } from "react-i18next";

interface ModalProps {
	modalShow: boolean;
	modalHide: () => void;
	children: any;
	shortcode?: string;
	uri?: string;
	type?: string;
	dataService?: IDataService;
}

interface IDataService {
	service: string | undefined;
	labelService: string | undefined;
	network: string | undefined;
}

function Modal({ modalShow, modalHide, shortcode, uri, dataService, type, children }: ModalProps) {

	const [loading, setLoading] = useState<boolean>(false);
	const [quantity, setQuantity] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [cost, setCost] = useState<number>();
	const [link, setLink] = useState<string>('');

	const { handleGetBalanceUser } = useAuth();

	const { t } = useTranslation();

	async function handleSendLikes(calculate: boolean) {

		try {

			if (!quantity) {
				setError(t('enterQuantity'));

				return;
			}

			setLoading(true);

			const response = await api.post('/users/send-services', {
				quantity: quantity,
				service: dataService ? dataService.service : 'ig101',
				link: dataService ? link : `https://instagram.com/p/${shortcode}`,
				calculate: calculate ? true : false
			});

			if (response.data.rate) {

				setLoading(false);
				setCost(response.data.rate);

				return;
			}

			if (response.status === 200 && !response.data.rate) {

				setLoading(false);
				setQuantity('');
				handleGetBalanceUser();

				modalHide();
			}

		} catch (error: any) {

			if (error.response.data.message === 'Insufficient funds') {

				setError(t('insufficientFunds'));
				setLoading(false);
			}
		}
	}

	return (
		<S.Container>

			{modalShow && (
				<>
					<S.Backdrop />
					<S.Wrapper>

						<S.Header>
							<S.TitleHeader>{t('newOrderModal')}</S.TitleHeader>
						</S.Header>

						<S.Content>

							<h2>{!dataService ? 'Enviar me gusta' : `Estás enviando ${dataService?.labelService} para el ${dataService?.network}`}</h2>

							{!dataService && (
								<img src={uri} alt="" />
							)}

							{dataService && (
								<S.InputText
									label="Link"
									type="text"
									value={link}
									placeholder="informar el enlace"
									onChange={(event: any) => {
										setLink(event.target.value);
										setError('');
									}}
								/>
							)}

							<S.InputText
								label={t('quantitysendLikesModal')}
								type="number"
								value={quantity}
								placeholder={t('quantitysendLikesModalPlaceholder')}
								onChange={(event: any) => {
									setQuantity(event.target.value);
									setError('');
								}}
								onBlur={() => handleSendLikes(true)}
							/>

							<S.Cost>{t('cost')}: {cost?.toFixed(2)}</S.Cost>

							<S.Error>{error}</S.Error>

							<S.ContentButtons>
								<S.ButtonAction
									type="button"
									model='noFilled'
									onClick={() => {
										modalHide();
										setQuantity('');
									}}
								>
									{t('buttonCancelModal')}
								</S.ButtonAction>

								<S.ButtonAction
									type="button"
									model='fill'
									onClick={() => handleSendLikes(false)}
									loading={loading}
								>
									{t('buttonSendLikesModal')}
								</S.ButtonAction>
							</S.ContentButtons>
						</S.Content>
					</S.Wrapper>
				</>
			)}
		</S.Container>
	);
};

export default Modal;