import React from 'react';

import * as S from './styles';

export default function Input({ ...rest }) {
	return (
		<S.Container>
			<label>{rest.label}</label>
			<input {...rest} />
		</S.Container>
	);
}