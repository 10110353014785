import styled from 'styled-components';

export const Container = styled.div`
	max-width: 550px;
	margin: 0 auto;
`;

export const Content = styled.div`
	background: #FFF;
	min-height: 100vh;
	padding: 10px 20px;
	position: relative;
`;

export const Label = styled.p`
	margin-top: 15px;
	margin-bottom: 5px;
	font-size: 14px;
`;

export const Info = styled.div`

`;

export const Description = styled.p`
	color: #272727;
	font-size: 15px;
	font-weight: 600;
	margin-top: 5px;
	margin-bottom: 15px;
	text-align: center;
`;

export const Note = styled.p`
	margin-bottom: 15px;
	text-align: center;
	color: red;
`;