import styled from 'styled-components';

export const Container = styled.div`
	border: 2px solid #3d3745;
	height: 55px;
	border-radius: 8px;
	margin: 10px 0;

	label {
		position: absolute;
		margin-top: -10px;
		margin-left: 10px;
		background: #FFF;
		padding: 0 4px;
		font-weight: 500;
		color: #3d3745;
	}
`;