import { useEffect } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import './styles/global.css';

import i18n from './i18n';

import { AuthProvider } from './hooks/auth';

import Routes from './routes';

function App() {

  useEffect(() => {

    const aux = window.location.origin.split(".")[0].split('//')[1];

    const lang = aux === 'mi-cuenta' ? 'es' : 'pt';

    if (lang) {

      i18n.changeLanguage(lang);
    }
  }, []);

  return (
    <Router>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </Router>
  );
}

export default App;
