import styled from 'styled-components';

export const Container = styled.div`
	max-width: 550px;
	margin: 0 auto;
`;

export const Content = styled.div`
	min-height: 80vh;
	padding: 10px;
`;

export const NoProfile = styled.h2`

	h2 {
		font-size: 15px;
		margin-top: 50%;
		color: #9797BD;
		text-align: center;
		font-weight: 500;
	}
`;

export const ContentPosts = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 70px;

	button {
		border: none;
		outline: none;
		width: 31%;
		cursor: pointer;
		margin-right: 8px;
		background: transparent;
	}
`;

export const Post = styled.img`
	width: 100%;
	margin: 5px 0;
	border-radius: 8px; 
`;