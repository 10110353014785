import styled from 'styled-components';

import Button from '../../components/Button';

import Input from '../../components/Input';

import backgroundImage from '../../assets/images/bg.png';

interface PropsContentHeader {
	uriProfile?: string;
}

export const Background = styled.div`

	background-image: url(${backgroundImage});
	background-repeat: no-repeat;
	background-position: center;
`;

export const Container = styled.div`

	max-width: 550px;
	margin: 0 auto;
	height: 100vh;
`;

export const ContentHeader = styled.div<PropsContentHeader>`

	height: 130px;
	display: flex;
	align-items: center;
	padding: 20px;
	background-size: cover;

	button {
		display: flex;
		align-items: center;
		background: none;
		border: none;

		h1 {
			font-size: 13px;
			color: #FFF;
			margin-right: 8px;
		}
	}
`;

export const Content = styled.div`
	
	padding: 10px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	h1 {
		font-size: 25px;
		font-weight: 800;
		text-align: center;
		margin-top: 20px;
	}
`;

export const ContentInput = styled.div`

	background: #FFF;
	border-radius: 5px;
	margin: auto 50px;
	padding: 35px 20px;

	p {

		font-size: 15px;
		text-align: center;
		color: #7e7e7e;
		font-weight: 500;
    	margin-top: 15px;
		margin-bottom: 15px
	}

	img {

		width: 170px;
		text-align: center !important;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		display: block;
	}
`;

export const InputText = styled(Input)`

	background: #FFF;
	border: 1px solid #FFF;
`;

export const ContentButtons = styled.div`

	display: flex;
	justify-content: center;
`;

export const ButtonAction = styled(Button)`
	margin: 10px auto;
	background: #e80566;
	border-color: #e80566;
	border-radius: 5px;
`;

export const Error = styled.span`
	color: #C10F41;
	text-align: center;
	font-size: 13px;
	margin-top: -5px;
`;

export const ForgotPassword = styled.div`
	
	margin-top: 12px;
	text-align: center;

	span {
		font-size: 13px;
		width: 130px;
	}
`;

export const ImageFooter = styled.img`

	width: 100%;
	position: fixed;
	bottom: 0;
`;