import styled, { css } from "styled-components";

interface PropsButton {
	model: 'fill' | 'noFilled';
}

export const Container = styled.button<PropsButton>`
	width: 170px;
	padding: 12px 20px;
	border-radius: 5px;

	background: blue;

	${({ model }) => model === 'fill' && css`
		border: 2px solid #683984;
		background: #683984; 
		color: #FFF;
	`}

	${({ model }) => model === 'noFilled' && css`
		border: 2px solid #3D3744;
		background: none; 
		color: #3D3744;
	`}
`;