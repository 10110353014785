import styled, { css } from "styled-components";

import Input from "../../components/InputText";

import Button from "../../components/Button";

interface IButton {
	model: string;
}

interface StatusProps {
	status: string;
}

interface ButtonProps {
	bg: string;
}

interface MessageProps {
	color: string;
}

export const Container = styled.div`
	max-width: 550px;
	margin: 0 auto;
`;

export const Content = styled.div`
	/* background: red; */
	/* border-radius: 15px 15px 0 0; */
	min-height: 100vh;
	padding: 0 10px 10px 10px;
	/* margin-top: -18px; */
	/* position: relative; */
	margin-bottom: 40px;
`;





export const Box = styled.div`

`;

export const Title = styled.h2`
	color: #272727;
	font-size: 15px;
	font-weight: 600;
	margin-top: 5px;
	margin-bottom: 10px;
`;

export const Subtitle = styled.h2`
	color: #272727;
	font-size: 13px;
	font-weight: 600;
	margin-top: 5px;
`;

export const Info = styled.p`
	color: #8280B9;
	font-size: 13px;
	margin: 2px 0;
`;

export const Hr = styled.div`
	height: 1px;
	background: #E5E5E5;
	width: 80%;
	margin: 15px auto;
`;

export const Plan = styled.div`
	background: #FFF;
	border-radius: 4px; 
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 10px 0;
	border-left: 3px solid green;
	padding-left: 5px;
`;

export const ColumnInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ColumnButtons = styled.div`
	display: flex;
	flex-direction: column;
	display: flex;
	justify-content: center;
`;

export const ButtonSettings = styled.button<IButton>`
	
	background: none;
	padding: 2px;
	
	border-radius: 4px;
	margin: 3px;
	width: 100px;
	font-size: 11px !important;

	${({ model }) => model === 'update' && css`
		border: 2px solid #445193;
		color: #445193;
	`}

	${({ model }) => model === 'cancel' && css`
		border: 2px solid #C10F41;
		color: #C10F41;
	`}
`;










export const Title2 = styled.p`
	color: #3d3745;
	font-size: 14px;
	margin: 10px 0 8px 15px;
`;

export const Card = styled.div`
	background: #FFF;
	border-radius: 8px;
	box-shadow: 0px 10px 20px rgba(71, 100, 230, 0.18), 0px 2px 6px rgba(71, 100, 230, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
	padding: 10px 15px;
	margin-bottom: 10px;
	position: relative;
	border: 2px solid #ebeaec;
`;

export const TextCard = styled.p`
	color: #3d3745;
	font-size: 13px;
	margin: 4px 0;
`;

export const Status = styled.div<StatusProps>`
	height: 12px;
	width: 12px;
	border-radius: 20px;
	position: absolute;
	right: 15px;

	${props => props.status === 'ACTIVE' && css`
		background: #7EF54E;
		animation-name: active;
		animation-duration: 1.8s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;

		@keyframes active {
			0% {
				transform: scale(.7);
				background-color: #31A204;
				border-radius: 100%;
			}
			50% {
				background-color: #236A06;
			}
		}
	`}

	${props => props.status === 'ACTIVE_LIKES' && css`
		background: #9D7BB4;
		animation-name: stretch;
		animation-duration: 1.8s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;

		@keyframes stretch {
			0% {
				transform: scale(.7);
				background-color: #833AB4;
				border-radius: 100%;
			}
			50% {
				background-color: #4F0283;
			}
		}
	`}

	${props => props.status === 'ACTIVE_CANCELED' && css`
		background: #FB998C;
		animation-name: canceled;
		animation-duration: 1.8s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;

		@keyframes canceled {
			0% {
				transform: scale(.7);
				background-color: #DB412C;
				border-radius: 100%;
			}
			50% {
				background-color: #8C1100;
			}
		}
	`}
`;

export const TextCardWarning = styled.p<StatusProps>`
	font-size: 13px;
	margin: 4px 0;

	${props => props.status === 'active_canceled' && css`
		color: #db412c;
	`}

	${props => props.status === 'active_likes' && css`
		color: #833ab4;
	`}
`;

export const ContentButtonsCard = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const ButtonCard = styled.button<ButtonProps>`
	background: #FFF;
	color: ${props => props.bg};
	border: none;
	margin-left: 10px;
	padding: 3px 4px;
	border-radius: 3px;
	cursor: pointer;
`;

export const TextButtonCard = styled.p`
	font-size: 12px;
`;

export const ButtonChangePassword = styled(Button)`
	&& {
		width: 100%;
 	}
`;

export const InputChangePassword = styled(Input)`
	width: 100%;
`;

export const MessageUpdate = styled.h2<MessageProps>`
	text-align: center;
	font-size: 13px;
	color: ${props => props.color};
	margin: 10px 0;
`;