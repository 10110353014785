import { useState } from 'react';

import * as S from './styles';

import Input from '../../components/Input';

import { Link, useParams } from 'react-router-dom';

import api from '../../services/api';

import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';

const uri = 'https://noomis-files-hmg.s3.amazonaws.com/content/62ea92b0-9c23-11ea-b65b-b3c135261253.jpeg';

interface IParams {
	token: string;
	email: string;
}

export default function ResetPassword() {

	const [loading, setLoading] = useState<boolean>(false);
	const [password, setPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [messageSuccess, setMessageSuccess] = useState<boolean>(false);

	const { token, email } = useParams<IParams>();

	const { t } = useTranslation();

	async function handleResetPassword() {
		try {

			if (!password || !confirmPassword) {

				setError(t('allRequiredFields'));

				return;
			}

			if (password !== confirmPassword) {

				setError(t('differentPasswords'));

				return;
			}

			setLoading(true);

			const lang = i18n.language === 'es' ? 'tbes' : 'tb';

			const response = await api.post('/users/reset-password', {
				email,
				token,
				password
			}, {
				headers: {
					company: lang
				}
			});

			if (response.status === 200) {

				setMessageSuccess(true);
				setPassword('');
				setConfirmPassword('');
				setLoading(false);
			}
		} catch (error: any) {

			setLoading(false);
			setPassword('');
			setConfirmPassword('');

			setError(t('expiredToken'));
		}
	}

	return (
		<S.Container>
			<S.ContentHeader uriProfile={uri} />

			<S.Content>

				{messageSuccess ? (
					<>
						<h1>{t('passwordChanged')}</h1>
						<p>{t('loginWithNewPassword')}</p>
					</>
				) : (
					<>
						<h1>{t('inputNewPasswordUpdatePasswordModal')}</h1>
						<p>{t('enterNewPassword')}</p>

						<S.ContentInput>
							<Input
								label={t('inputNewPasswordUpdatePasswordModal')}
								type="password"
								value={password}
								placeholder={t('enterNewPassword')}
								onChange={(event: any) => {
									setPassword(event.target.value);
									setError('');
								}}
							/>

							<Input
								label={t('confirmNewPassword')}
								type="password"
								value={confirmPassword}
								placeholder={t('enterNewPassword')}
								onChange={(event: any) => {
									setConfirmPassword(event.target.value);
									setError('');
								}}
							/>
						</S.ContentInput>

						<S.Error>{error}</S.Error>

						<S.ButtonAction
							type="button"
							model='fill'
							loading={loading}
							onClick={() => handleResetPassword()}
						>
							{t('buttonUpdatePassword')}
						</S.ButtonAction>
					</>
				)}

				<S.ForgotPassword>
					<Link to="/">
						<span>{t('backToLogin')}</span>
					</Link>
				</S.ForgotPassword>
			</S.Content>
		</S.Container>
	);
}